import { useLocation } from "@solidjs/router";
import { ParentProps, createContext, createSignal, useContext } from "solid-js";
import { SelectItem } from "~/components/Sidebar/types";

export const items: SelectItem[] = [
  { label: "Model Playground", value: "model" },
  { label: "Agent Workspace", value: "agent" },
];

const usePlaygroundProvider = (showConversations_ = false) => {
  const { pathname } = useLocation();
  const [showConversations, setShowConversations] = createSignal(showConversations_);
  const [selectedItem, setSelectedItem] = createSignal<SelectItem[]>([
    pathname.startsWith("/playground") ? items[0] : items[1],
  ]);

  const currentMode = () => {
    // if (selectedItem()[0].value === "model") {
    //   return "model";
    // } else {
    //   return "agent";
    // }
    return "model";
  };

  return [
    { showConversations, selectedItem, currentMode },
    {
      toggleConversation: () => setShowConversations(!showConversations()),
      handleSelectChange: (e: any) => setSelectedItem(e.items),
    },
  ] as const;
};

type PlaygroundContextType = ReturnType<typeof usePlaygroundProvider>;

const PlaygroundContext = createContext<PlaygroundContextType>();

export default function PlaygroundProvider(props: ParentProps) {
  const value = usePlaygroundProvider();

  return <PlaygroundContext.Provider value={value}>{props.children}</PlaygroundContext.Provider>;
}

export const usePlayground = () => useContext(PlaygroundContext)!;
